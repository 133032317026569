.canvas-row .profile-pic img
{
    height: 40px;
    width: 40px;
    border-radius: 50px;
    /* border: 2px solid black; */
    cursor: pointer;
}

.canvas-row .profile-pic
{
    text-align: center;
    padding: 0px;
}