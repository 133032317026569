button {
    margin: 5px;
    padding: 8px 40px;
    border: none;
    font-size: 22px;
    background-color: indigo;
    color: white;
    transition-duration: 0.2s;
}

button:hover {
    background-color: rgb(121, 1, 207);
}

button:active {
    background-color: rgb(49, 0, 83);
}

input {
    padding: 8px 20px;
    margin: 5px;
    border: 1px solid grey;
}

label {
    font-size: 22px;
    font-weight: normal;
}

li {
    margin-left: 50px;
}
