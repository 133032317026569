.canvas-row
{
    padding: 1rem;
    border: 1px solid #e0e0e0;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    font-weight: 200;
}

.canvas-row h6
{
    font-size: 0.85rem;
    margin-bottom: 0rem;
    color: grey;
    font-style: italic;
    margin-top: 0.5rem;
}

select{
    margin-top: 5px;
}