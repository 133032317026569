.side-nav {
    position: fixed;
    display: flex;
    height: 100vh;
    width: 10%;
    top: 0;
    left: 0;
    background: #33f;
    padding: 10px;
    flex-direction: column;
    box-shadow: 10px 0px 20px rgba(0, 0, 0, 0.3);
}

.side-nav button {
    padding: 10px 20px;
    cursor: pointer;
    color: white !important;
    background: #44f;
    font-size: 22px;
    transition-duration: 0.3s;
}

.side-nav button:hover {
    background-color: #55f;
}
