.question .main
{
    text-align: left;
    font-size: 1.3rem;
    padding-left: 1rem;
    font-weight: bold;
}

.question .row
{
    padding: 1rem;
}

.question input[type="text"], .option input[type="text"]
{
    border: none;
    border-bottom: 1px solid black;
    border-radius: 0px;
    height: 100%;
}

.option input[type="file"]
{
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.option label
{
    position: relative;
}


.question .help_text
{
    text-align: left;
    font-size: 1rem;
    padding-left: 1rem;
}

.question .tag
{
    text-align: left;
    font-size: 1rem;
    color: rgb(131, 29, 131);
}

.question
{
    padding: 2rem;
    border: 1px dashed #ccc;
}

.question .btn
{
    min-width: 100px;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    background-color: white;
    color: black;
    border: 1px solid black;
    border-radius: 0px;
    margin-right: 1rem;
    margin-top: 1rem;
}

.option p
{
    text-align: center;
}