#comments{

}

#comments .comment-tile{
  padding: 1rem;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  margin-bottom: 2rem;
}

#comments .user-profile{
  margin: 0 1rem;
}

#comments .user-profile img{
  width: 4rem;
  border-radius: 50%;
}

#comments .user-profile .name-time-container{
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#comments .user-profile .name-time-container h6{
  margin-bottom: 0;
  font-weight: bold;
}

#comments .user-profile .name-time-container p{
  margin-bottom: 0;
  font-weight: bold;
  color:#999999;
}

#comments .comment-text-container{
  flex:1;
  margin: 1rem;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  padding: 0.5rem;
}

#comments .comment-actions-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: max-content;
}

#comments .comment-actions-container button{
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 0.5rem 2rem;
  font-size: 1rem;
  flex:1;
  height: max-content;
}

#comments .comment-actions-container .accept-btn{
  border-color: green;
  color:green
}

#comments .comment-actions-container .accept-btn:hover{
  background-color: green;
  color:#fff
}

#comments .comment-actions-container .decline-btn{
  border-color: red;
  color:red
}

#comments .comment-actions-container .decline-btn:hover{
  background-color: red;
  color: #fff;
}

#comments .comment-actions-container .view-canvas-btn:hover{
  background-color: #000;
  color: #fff;
}

#comments .comment-tile .images-container .img-fluid{
  margin:0.4rem  
}

@media (min-width: 300px){
  #comments .comment-text-container{
    flex:1;
    margin: 0.5rem;
    /* min-width: 300px; */
  }
  
  #comments .comment-actions-container{
    flex:1;
    min-width: 200px;
  }
  #comments .user-profile .name-time-container{
    margin-left: 0.5rem;
  }
  #comments .comment-tile .images-container{
    margin: 0.5rem;
  }
  #comments .comment-tile .images-container .img-fluid{
    margin:0.4rem  ;
    max-width: 100%;
    max-width: -moz-available;          /* WebKit-based browsers will ignore this. */
    max-width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    max-width: fill-available;
  }
}

@media (min-width: 600px){
  #comments .comment-text-container{
    flex:2;
    min-width:auto;
    margin:1rem;
  }
  
  #comments .comment-actions-container{
    flex:1;
    min-width: 100px;
  }
  #comments .user-profile .name-time-container{
    margin-left: 1rem;
  }
  #comments .comment-tile .images-container{
    margin: 1rem;
  }
  #comments .comment-tile .images-container .img-fluid{
    margin:0.4rem  ;
    max-width: 15rem;
  }
}