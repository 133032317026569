body {
    font-family: sofia-pro-soft, sans-serif;
    font-weight: 700;
    font-style: normal;
    padding: 2rem;
}

.card-details label {
    width: 100%;
}
